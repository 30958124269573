"use client";

import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { BACKEND_URL } from "../../data/urls";
import Spinner from "../../components/Spinner";
import Navbar from "../../component/Navbar/navbar";

import DesignA from "./dashboard_A";
import DesignB from "./dashboard_B";
import DesignC from "./dashboard_C";
import DesignD from "./dashboard_D";

export function CyberinsuranceDashboard() {
  const [activeTab, setActiveTab] = useState("B");
  const [technicalScore, setTechnicalScore] = useState(0);
  const [nonTechnicalScore, setNonTechnicalScore] = useState(0);
  const [companyName, setCompanyDomain] = useState("");
  const [technicalResults, setTechnicalResults] = useState([]);
  const [nonTechnicalResults, setNonTechResults] = useState([]);
  const [aiResults, setAiResults] = useState([]);
  const [threats, setThreats] = useState([]);
  const [loading, setLoading] = useState(true);

  const params = useParams();

  useEffect(() => {
    const fetchReportData = async () => {
      try {
        setLoading(true);
        const authToken = localStorage.getItem("token");
        const reportId = params.id;
        const response = await fetch(
          `${BACKEND_URL}/reports/details/${reportId}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch report data");
        }
        const data = await response.json();
        console.log(data);
        setTechnicalScore(data?.report?.technical_score || 0);
        setNonTechnicalScore(data?.report?.non_technical_score || 0);
        setTechnicalResults(data?.results?.Technical || []);
        setNonTechResults(data?.results["Non-Technical"] || []);
        setAiResults(data?.results.AI || []);
        setThreats(data?.threat_groups || {});
        setCompanyDomain(data?.company?.domain);
      } catch (err) {
        throw err; // Pass the error to the parent
      } finally {
        setLoading(false);
      }
    };

    // Only fetch data when loading is false
    fetchReportData();
  }, [params.id]);

  const overallScore = Math.round(technicalScore + nonTechnicalScore);

  const renderTabContent = () => {
    const commonProps = {
      companyName,
      technicalScore,
      nonTechnicalScore,
      overallScore,
      technicalResults,
      nonTechnicalResults,
      aiResults,
      threats,
      reportId: params.id,
    };

    switch (activeTab) {
      case "A":
        return <DesignA {...commonProps} />;
      case "B":
        return <DesignB {...commonProps} />;
      case "C":
        return <DesignC {...commonProps} />;
      case "D":
        return <DesignD {...commonProps} />;
      default:
        return null;
    }
  };

  return loading ? (
    <div className="h-screen flex items-center justify-center">
      <Spinner />
    </div>
  ) : (
    <div className="min-h-screen bg-gradient-to-b to-transparent from-indigo-600/20 pt-[76px]">
      <Navbar />
      <div className="space-y-6 max-w-7xl mx-auto py-12 mt-10 px-4 sm:px-6 lg:px-8">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Dashboard designs">
            {["A", "B", "C", "D"].map((tab) => (
              <button
                key={tab}
                onClick={() => setActiveTab(tab)}
                className={`
                py-4 px-1 border-b-2 font-medium text-sm
                ${
                  activeTab === tab
                    ? "border-blue-500 text-blue-600"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
                }
              `}
              >
                Design {tab}
              </button>
            ))}
          </nav>
        </div>
        {renderTabContent()}
      </div>
    </div>
  );
}

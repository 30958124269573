import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  FaLock,
  FaDesktop,
  FaShieldAlt,
  FaFish,
  FaExclamationTriangle,
  FaBuilding,
  FaDatabase,
  FaKey,
  FaBug,
  FaMoneyBillAlt,
  FaHdd,
  FaChartBar,
  FaUserShield,
  FaTools,
  FaUserLock,
  FaArrowRight,
  FaQuestionCircle,
} from "react-icons/fa";

// Ordered list of icons
const questionIcons = [
  FaLock,
  FaDesktop,
  FaShieldAlt,
  FaFish,
  FaExclamationTriangle,
  FaBuilding,
  FaDatabase,
  FaKey,
  FaBug,
  FaMoneyBillAlt,
  FaHdd,
  FaChartBar,
  FaUserShield,
  FaTools,
  FaUserLock,
];

const Questionnaire = ({
  questions,
  isLoading,
  error,
  onSubmit,
  isSubmitting,
}) => {
  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-center">
          <div className="animate-spin h-8 w-8 mb-4 border-4 border-indigo-600 border-t-transparent rounded-full mx-auto"></div>
          <p className="text-gray-600">Loading your questionnaire...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-center p-6 bg-red-50 rounded-lg max-w-md">
          <svg
            className="h-12 w-12 text-red-500 mx-auto mb-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
            />
          </svg>
          <h3 className="text-lg font-semibold text-red-800 mb-2">
            Unable to Load Questions
          </h3>
          <p className="text-red-600">{error}</p>
          <p className="text-gray-600 mt-2">
            Please refresh the page or try again later.
          </p>
        </div>
      </div>
    );
  }

  // Create Yup validation schema dynamically
  const createValidationSchema = () => {
    const schemaShape = {};
    questions.forEach((question) => {
      if (question.type === "Yes/No") {
        schemaShape[question.id] = Yup.string()
          .required("Please select either Yes or No for this question")
          .oneOf(["Yes", "No"], "Please select either Yes or No");
      } else if (question.type === "Text") {
        schemaShape[question.id] = Yup.string()
          .required("A response is needed for this question")
          .min(3, "Please provide a more detailed response");
      }
    });
    return Yup.object().shape(schemaShape);
  };

  const initialValues = questions.reduce((values, question) => {
    values[question.id] = "";
    return values;
  }, {});

  return (
    <div className="container-fluid py-2 min-h-screen bg-gradient-to-b to-transparent from-indigo-600/20">
      <div className="max-w-screen-lg mx-auto bg-white p-6 rounded-lg shadow-lg">
        <h1 className="text-3xl font-bold text-gray-800 text-center">
          Cyber Posture Questionnaire
        </h1>
        <p className="text-gray-600 text-center mb-6">
          Answer accurately to assess your security stance
        </p>

        <Formik
          initialValues={initialValues}
          validationSchema={createValidationSchema()}
          onSubmit={(values, { resetForm }) => {
            onSubmit(values);
            resetForm();
          }}
        >
          {({ values, setFieldValue }) => (
            <Form className="space-y-6 grid grid-cols-3 gap-6">
              <div className="col-span-3 space-y-6">
                {questions.map((question, index) => {
                  if (!question.id) {
                    console.error(
                      `Question id is missing for question:`,
                      question
                    );
                    return null; // Skip the question if no id
                  }
                  const IconComponent =
                    questionIcons[index] || FaQuestionCircle;
                  return (
                    <div
                      key={question.id}
                      className="p-2 bg-gray-50 rounded-lg border border-gray-200 grid grid-cols-9 gap-2"
                    >
                      {/* Dynamic Icon */}
                      <div className="flex justify-center items-center">
                        <IconComponent className="text-[#867EF7] text-4xl" />
                      </div>

                      {/* Question & Input */}
                      <div className="col-span-8 space-y-2">
                        <label
                          htmlFor={question.id}
                          className="block text-base font-semibold text-gray-800"
                        >
                          {question.label}
                        </label>

                        {/* Yes/No or Textbox */}
                        {question.type === "Yes/No" ? (
                          <div className="flex gap-6">
                            {["Yes", "No"].map((option) => (
                              <button
                                key={option}
                                type="button"
                                className={`flex-1 py-2 rounded-md text-center font-medium transition-all
                                ${
                                  values[question.id] === option
                                    ? "bg-[#867ef7] text-white" // Selected - Darker Purple
                                    : "bg-gray-200 text-gray-700 hover:bg-[#d0ccfc] hover:text-gray-900" // Default - Gray, Hover - Light Purple
                                }`}
                                onClick={() =>
                                  setFieldValue(question.id, option)
                                }
                              >
                                {option}
                              </button>
                            ))}
                          </div>
                        ) : question.type === "Text" ? (
                          <Field
                            id={question.id}
                            name={question.id}
                            as="textarea"
                            className="w-full border-gray-300 rounded-md shadow-sm p-3 focus:ring-blue-500 focus:border-blue-500"
                            placeholder={
                              question.placeholder ||
                              "Please explain your policy"
                            }
                            rows="3"
                          />
                        ) : null}

                        <ErrorMessage
                          name={question.id}
                          component="p"
                          className="text-red-500 text-sm mt-2"
                        />
                      </div>
                    </div>
                  );
                })}

                <div className="flex justify-center pt-6">
                  <button
                    type="submit"
                    className="py-2 px-8 flex items-center font-semibold bg-indigo-600 hover:bg-indigo-700 text-white rounded-full disabled:opacity-50"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Submitting..." : "Submit Questionnaire"}
                    <FaArrowRight className="ml-2 text-sm" />
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Questionnaire;

import { useState, useEffect } from "react";
import CountUp from "react-countup";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { ShieldAlert, Search } from "lucide-react";
import React from "react";
import { BACKEND_URL } from "../../data/urls";
import Spinner from "../../components/Spinner";

export default function DesignB({
  companyName,
  technicalScore,
  nonTechnicalScore,
  overallScore,
  threats,
  reportId,
}) {
  const [animate, setAnimate] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimate(true);
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  const getScorePercentage = (score, weight) => {
    return Math.round((score / weight) * 100);
  };

  const handleGeneratePDF = async (e) => {
    e.stopPropagation();
    setIsGeneratingPDF(true);

    try {
      const token = localStorage.getItem("token");
      // Make API request to generate PDF
      const response = await fetch(
        `${BACKEND_URL}/reports/generate-pdf/${reportId}`,
        {
          method: "GET",
          headers: {
            Accept: "application/pdf",
            // Add any auth headers if needed
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to generate PDF");
      }

      // Get the PDF blob
      const blob = await response.blob();

      // Create download link
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = `${companyName}-security-report.pdf`; // Set filename

      // Trigger download
      document.body.appendChild(link);
      link.click();
      link.remove();

      // Cleanup
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error("Error generating PDF:", error);
    } finally {
      setIsGeneratingPDF(false);
    }
  };

  return (
    <>
      {isGeneratingPDF && (
        <div className="fixed inset-0 bg-black/20 backdrop-blur-sm flex flex-col items-center justify-center z-50">
          <Spinner />
          <p className="mt-4 text-gray-800 font-medium">Generating PDF...</p>
        </div>
      )}
      <div className="space-y-6 min-h-screen pb-32">
        <div className="text-center">
          <div className="inline-block px-8 py-4 rounded-lg bg-gray-100 border border-gray-200 shadow-sm">
            <h1 className="text-3xl font-bold text-gray-900 inline-flex items-center gap-3">
              <Search className="w-5 h-5 text-gray-400 hover:text-gray-600 transition-colors" />
              {companyName || "Company Domain"}
            </h1>
          </div>
        </div>

        {/* Score Cards - Same as Design A */}
        <div className="p-6">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 items-center">
            {/* Technical Score Card */}
            <div className="bg-white rounded-lg shadow-md p-6 flex flex-col items-center">
              <h2 className="text-xl font-semibold mb-4">Technical Score</h2>
              <div className="relative w-28 h-28">
                <CircularProgressbar
                  value={animate ? getScorePercentage(technicalScore, 10) : 0}
                  text=""
                  styles={buildStyles({
                    rotation: 0,
                    strokeLinecap: "round",
                    textSize: "16px",
                    pathTransitionDuration: 2,
                    pathColor:
                      getScorePercentage(technicalScore, 10) >= 70
                        ? "rgb(75, 160, 60)"
                        : getScorePercentage(technicalScore, 10) >= 50
                        ? "rgb(230, 190, 70)"
                        : "rgb(190, 60, 60)",
                    trailColor: "rgb(210, 210, 210)",
                    pathTransition: "stroke-dashoffset 2s ease 0s",
                  })}
                />
                <div className="absolute inset-0 flex items-center justify-center">
                  <CountUp
                    end={getScorePercentage(technicalScore, 10)}
                    duration={2}
                    suffix="%"
                    className="text-2xl font-bold"
                  />
                </div>
              </div>
            </div>

            {/* Overall Score Card */}
            <div className="bg-white rounded-lg shadow-lg p-8 flex flex-col items-center">
              <h2 className="text-2xl font-semibold mb-4">
                Overall Security Score
              </h2>
              <div className="relative w-40 h-40">
                <CircularProgressbar
                  value={animate ? overallScore : 0}
                  text=""
                  styles={buildStyles({
                    rotation: 0,
                    strokeLinecap: "round",
                    textSize: "16px",
                    pathTransitionDuration: 2,
                    pathColor:
                      overallScore >= 70
                        ? "rgb(75, 160, 60)"
                        : overallScore >= 50
                        ? "rgb(230, 190, 70)"
                        : "rgb(190, 60, 60)",
                    trailColor: "rgb(210, 210, 210)",
                    pathTransition: "stroke-dashoffset 2s ease 0s",
                  })}
                />
                <div className="absolute inset-0 flex items-center justify-center">
                  <CountUp
                    end={overallScore}
                    duration={2}
                    suffix="%"
                    className="text-3xl font-bold"
                  />
                </div>
              </div>
            </div>

            {/* Non-Technical Score Card */}
            <div className="bg-white rounded-lg shadow-md p-6 flex flex-col items-center">
              <h2 className="text-xl font-semibold mb-4">
                Non-Technical Score
              </h2>
              <div className="relative w-28 h-28">
                <CircularProgressbar
                  value={
                    animate ? getScorePercentage(nonTechnicalScore, 90) : 0
                  }
                  text=""
                  styles={buildStyles({
                    rotation: 0,
                    strokeLinecap: "round",
                    textSize: "16px",
                    pathTransitionDuration: 2,
                    pathColor:
                      getScorePercentage(nonTechnicalScore, 90) >= 70
                        ? "rgb(75, 160, 60)"
                        : getScorePercentage(nonTechnicalScore, 90) >= 50
                        ? "rgb(230, 190, 70)"
                        : "rgb(190, 60, 60)",
                    trailColor: "rgb(210, 210, 210)",
                    pathTransition: "stroke-dashoffset 2s ease 0s",
                  })}
                />
                <div className="absolute inset-0 flex items-center justify-center">
                  <CountUp
                    end={getScorePercentage(nonTechnicalScore, 90)}
                    duration={2}
                    suffix="%"
                    className="text-2xl font-bold"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Threat Group Grid */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-20">
          {Object.entries(threats).map(([groupName, groupData]) => (
            <div
              key={groupName}
              className="bg-white rounded-lg shadow-md p-6 cursor-pointer hover:shadow-lg transition-shadow"
              onClick={() =>
                setSelectedGroup({ name: groupName, data: groupData })
              }
            >
              <h3 className="text-xl font-semibold mb-4 text-center">
                {groupName}
              </h3>
              <div className="flex justify-center">
                <ShieldAlert
                  className={`w-20 h-20 ${
                    groupData.probability >= 0.7
                      ? "text-[rgb(190,60,60)]"
                      : groupData.probability >= 0.4
                      ? "text-[rgb(230,190,70)]"
                      : "text-[rgb(75,160,60)]"
                  }`}
                />
              </div>
              <div className="mt-4 text-center text-sm text-gray-600">
                <button
                  onClick={handleGeneratePDF}
                  className="hover:underline cursor-pointer"
                >
                  Risk Level: {Math.round(groupData.probability * 100)}%
                </button>
              </div>
            </div>
          ))}
        </div>

        {/* Modal */}
        {selectedGroup && (
          <div
            className="fixed inset-0 backdrop-blur-lg flex items-center justify-center p-4 z-50"
            onClick={() => setSelectedGroup(null)}
          >
            <div
              className="bg-white/95 rounded-lg p-6 max-w-3xl w-full max-h-[80vh] overflow-y-auto relative border border-gray-200 shadow-xl transition-all duration-200 ease-out transform scale-100 opacity-100"
              style={{
                animation: "modalFadeIn 0.3s ease-out",
              }}
              onClick={(e) => e.stopPropagation()}
            >
              <div className="flex justify-between items-start mb-6 sticky top-0 bg-white/95 pb-2 border-b">
                <h2 className="text-2xl font-bold">{selectedGroup.name}</h2>
                <button
                  onClick={() => setSelectedGroup(null)}
                  className="text-gray-500 hover:text-gray-700 p-2 rounded-full hover:bg-gray-100"
                >
                  ✕
                </button>
              </div>

              <div className="flex flex-col md:flex-row items-center md:w-full mb-4">
                <div className="flex flex-col items-center md:w-1/3 mb-4 md:mb-0">
                  <ShieldAlert
                    className={`w-24 h-24 mb-2 ${
                      selectedGroup.data.probability >= 0.7
                        ? "text-[rgb(190,60,60)]"
                        : selectedGroup.data.probability >= 0.4
                        ? "text-[rgb(230,190,70)]"
                        : "text-[rgb(75,160,60)]"
                    }`}
                  />
                  <div className="text-lg font-semibold text-center">
                    Risk Level:{" "}
                    {Math.round(selectedGroup.data.probability * 100)}%
                  </div>
                </div>

                <div className="text-sm text-gray-600 text-center md:text-left md:w-2/3 prose prose-sm">
                  <div className="text-gray-600">
                    <h3 className="text-lg font-semibold mb-2">Top Threats:</h3>
                    <div className="grid grid-cols-[30px_1fr] gap-x-2 gap-y-2 bg-white/30 filter blur-sm select-none">
                      {selectedGroup.data.threats &&
                      typeof selectedGroup.data.threats === "object" ? (
                        Object.keys(selectedGroup.data.threats)
                          .slice(0, 3)
                          .map((key, index) => (
                            <React.Fragment key={index}>
                              <div className="font-semibold">{index + 1}.</div>
                              <div>{key}</div>
                            </React.Fragment>
                          ))
                      ) : (
                        <div className="col-span-2">No threats available</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {selectedGroup.data.summary && (
                <div className="mt-6 pt-4 border-t">
                  <h3 className="text-lg font-semibold mb-2">Summary:</h3>
                  <p className="text-gray-600 whitespace-pre-wrap text-justify leading-relaxed">
                    {selectedGroup.data.summary}
                  </p>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
}
